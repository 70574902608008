import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import TransitionPage from 'src/components/shared/TransitionPage'
import { useSelector } from 'react-redux'
import PvolveSelectors from '@pvolve/sdk/src/app/selectors'

const GettingStarted = () => {
    // const shopifyUrl = useSelector(PvolveSelectors.config.shopifyUrl) || 'https://www.pvolve.com';
    // const trialUrl = useSelector(PvolveSelectors.config.trialUrl);

    // useEffect(() => {
    //     if (!!shopifyUrl && !!trialUrl?.redirect) {
    //         navigate(`${shopifyUrl}${trialUrl?.redirect}`);
    //     }
    // }, [shopifyUrl, trialUrl]);

    // return <TransitionPage />;
    window.location.reload()
    return null
}

export default GettingStarted
